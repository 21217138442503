<template>
  <b-modal
    :should-show="shouldShow"
    :on-enter-key-press="updateUser"
    hide-footer
    size="lg"
    :title="$t('edit') + ' ' + $t('user')"
    @hidden="shouldShow = false"
  >
    <div class="d-block text-center">
      <b-overlay :show="loading" rounded="sm">
        <div class="row">
          <div class="col-6 text-left">
            <div class="form-group">
              <label>{{ $t('firstName') }}:</label>
              <input
                v-model="user.firstName"
                type="text"
                class="form-control"
                readonly
              />
            </div>
          </div>
          <div class="col-6 text-left">
            <div class="form-group">
              <label>{{ $t('lastName') }}:</label>
              <input
                v-model="user.lastName"
                type="text"
                class="form-control"
                readonly
              />
            </div>
          </div>
          <div class="col-6 text-left">
            <div class="form-group">
              <label>{{ $t('userpool') }}:</label>
              <input
                v-model="user.attributes.userpool"
                type="text"
                class="form-control"
                readonly
              />
            </div>
          </div>
          <div class="col-6 text-left">
            <label>{{ $t('overrideUserpool') }}:</label>
            <multiselect
              v-model="user.attributes.overrideUserpool"
              :options="user.attributes.availableUserpools"
              :multiple="true"
              label="userpool"
              track-by="userpool"
              :close-on-select="true"
              placeholder="Select Userpool"
            />
          </div>
          <div class="col-12 pb-3 pt-3 border-bottom mb-3 text-left">
            <h5 class="bolder">Exceptions to Building Settings</h5>
          </div>
          <div class="col-12 text-left">
            <label>{{ $t('futureReservationMaximum') }}:</label>
            <div class="input-group">
              <input
                v-model="user.attributes.futureReservationMaximum"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-6 text-left pt-3">
            <div class="form-group">
              <label>{{ $t('weeklyReservationHourLimit') }}:</label>
              <span
                v-b-tooltip.hover
                class="tip-icon float-right mr-2"
                :title="$t('weeklyReservationLimitTip')"
              >
                <font-awesome-icon icon="question-circle"
              /></span>
              <div class="input-group">
                <input
                  v-model="user.attributes.weeklyReservationHourLimit"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-6 text-left pt-3">
            <label>{{ $t('weeklyReservationMinuteLimit') }}:</label>
            <span
              v-b-tooltip.hover
              class="tip-icon float-right mr-2"
              :title="$t('weeklyReservationLimitTip')"
            >
              <font-awesome-icon icon="question-circle" />
            </span>
            <div class="input-group">
              <input
                v-model="user.attributes.weeklyReservationMinuteLimit"
                class="form-control"
              />
              <b-form-invalid-feedback
                v-if="
                  parseInt(
                    user.attributes.weeklyReservationMinuteLimit,
                    10
                  ) > 59
                "
              >
                {{ $t('weeklyReservationMinuteWarning') }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-6 text-left">
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  id="checkBoxInvite"
                  v-model="user.attributes.isAllowedToInviteGuests"
                  type="checkbox"
                  :disabled="
                    enableAllUsersToInviteGuests ||
                      !isAllowedToChangeRoles
                  "
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label"
                  for="checkBoxInvite"
                  >{{ $t('inviteGuests') }}</label
                >
              </div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  id="checkBoxTeamLeadRole"
                  v-model="user.attributes.isTeamLead"
                  type="checkbox"
                  :disabled="!isAllowedToChangeRoles"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label"
                  for="checkBoxTeamLeadRole"
                  >{{ $t('teamLeadRole') }}</label
                >
              </div>
            </div>

            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  id="checkBox-Customer-coordinator"
                  v-model="user.attributes.isCustomerCoordinator"
                  type="checkbox"
                  :disabled="!isAllowedToChangeRoles"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label"
                  for="checkBox-Customer-coordinator"
                  >{{ $t('customerCoordinator') }}</label
                >
              </div>
            </div>

            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  id="checkBox-insights"
                  v-model="user.attributes.viewInsights"
                  type="checkbox"
                  :disabled="!isAllowedToChangeRoles"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label"
                  for="checkBox-insights"
                  >{{ $t('insights') }}</label
                >
              </div>
            </div>

            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  id="checkBox-view-custom-assets"
                  v-model="user.attributes.viewCustomAssets"
                  type="checkbox"
                  :disabled="!isAllowedToChangeRoles"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label"
                  for="checkBox-view-custom-assets"
                  >{{ $t('viewCustomAssets') }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
      <br />
      <b-button
        variant="danger"
        class="mt-1 mr-2"
        @click="$emit('cancel')"
      >
        {{ $t('cancel') }}
      </b-button>
      <b-button class="mt-1" primary @click="saveUpdateUser(user)">
        {{ $t('save') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import Multiselect from 'vue-multiselect'
import _has from 'lodash/has'
import { ONLY_NUMBER } from '@/constants'
import AttributesMixin from '@/mixins/AttributesMixin'

import 'vue-multiselect/dist/vue-multiselect.min.css'

const DEFAULT_ATTRIBUTES = {}

export default {
  name: 'UserModal',
  components: {
    Multiselect,
    'b-modal': BootstrapModalHOC
  },
  mixins: [AttributesMixin],
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      shouldShow: this.show,
      user: {
        attributes: {
          availableUserpools: []
        }
      },
      overrideUserpool: false,
      insightsRoleState: null,
      teamLeadRoleState: null,
      viewCustomAssetsRoleState: null,
      customerCoordinatorRoleState: null,
      isAllowedToChangeRoles: false
    }
  },
  computed: {
    ...mapState('users', {
      loading: 'loading'
    }),
    ...mapGetters('common', {
      loggedInUsersRoles: 'loggedInUsersRoles',
      enableAllUsersToInviteGuests: 'enableAllUsersToInviteGuests'
    })
  },
  watch: {
    show() {
      this.shouldShow = this.show
    },
    'user.attributes': {
      handler(value) {
        this.user.attributes.futureReservationMaximum = value.futureReservationMaximum?.replace(
          ONLY_NUMBER.number,
          ''
        )
        this.user.attributes.weeklyReservationHourLimit = value.weeklyReservationHourLimit?.replace(
          ONLY_NUMBER.number,
          ''
        )
        this.user.attributes.weeklyReservationMinuteLimit = value.weeklyReservationMinuteLimit?.replace(
          ONLY_NUMBER.number,
          ''
        )
      },
      deep: true,
      immediate: true
    },
    shouldShow() {
      if (!this.shouldShow) {
        this.$emit('done')
      }
    },
    overrideUserpool(newValue, oldValue) {
      if (!newValue) {
        this.user.attributes.overrideUserpool = null
      }
    },
    async userId(newValue) {
      if (newValue) {
        const user = await this.getUserById(newValue)
        user.attributes = user.attributes || DEFAULT_ATTRIBUTES
        const loginUserRoles = this.loggedInUsersRoles
        await this.isAuthorizedToChangeRoles(
          user.clientRoles,
          loginUserRoles
        )

        // user attribute values in KeyCloack are stored in string type , converting in boolean
        user.attributes.isAllowedToInviteGuests = user.attributes
          .isAllowedToInviteGuests
          ? JSON.parse(
              user.attributes.isAllowedToInviteGuests.toLowerCase()
            )
          : false

        if (user.clientRoles.includes('Customer-team-lead')) {
          user.attributes.isTeamLead = user.attributes.isTeamLead
            ? JSON.parse(user.attributes.isTeamLead.toLowerCase())
            : true
        } else {
          user.attributes.isTeamLead = false
        }

        if (user.clientRoles.includes('Customer-coordinator')) {
          user.attributes.isCustomerCoordinator = user.attributes
            .isCustomerCoordinator
            ? JSON.parse(
                user.attributes.isCustomerCoordinator.toLowerCase()
              )
            : true
        } else {
          user.attributes.isCustomerCoordinator = false
        }

        if (user.clientRoles.includes('Insights')) {
          user.attributes.viewInsights = user.attributes.viewInsights
            ? JSON.parse(user.attributes.viewInsights.toLowerCase())
            : true
        } else {
          user.attributes.viewInsights = false
        }

        this.insightsRoleState = user.attributes.viewInsights
        this.teamLeadRoleState = user.attributes.isTeamLead
        this.viewCustomAssetsRoleState =
          user.attributes.viewCustomAssets
        this.customerCoordinatorRoleState =
          user.attributes.isCustomerCoordinator

        this.user = user
        this.overrideUserpool = _has(
          user,
          'attributes.overrideUserpool'
        )
      } else {
        this.shouldShow = false
      }
    }
  },
  methods: {
    ...mapActions('users', ['getUserById', 'updateUser']),

    async isAuthorizedToChangeRoles(
      editUserRoles,
      loggedInUserRoles
    ) {
      const loggedInUserRole = this.findUserRole(loggedInUserRoles)

      if (!loggedInUserRole) {
        this.isAllowedToChangeRoles = false
      }

      const editUserRole = this.findUserRole(editUserRoles)

      if (loggedInUserRole === 'Dashboard-admin') {
        this.isAllowedToChangeRoles = !['Dashboard-admin'].includes(
          editUserRole
        )
      }

      if (loggedInUserRole === 'Customer-admin') {
        this.isAllowedToChangeRoles = ![
          'Customer-admin',
          'Dashboard-admin'
        ].includes(editUserRole)
      }

      if (loggedInUserRole === 'Customer-coordinator') {
        this.isAllowedToChangeRoles = ![
          'Customer-coordinator',
          'Customer-admin',
          'Dashboard-admin'
        ].includes(editUserRole)
      }
    },

    findUserRole(userRoles) {
      if (userRoles.includes('Dashboard-admin')) {
        return 'Dashboard-admin'
      }

      if (userRoles.includes('Customer-admin')) {
        return 'Customer-admin'
      }

      if (userRoles.includes('Customer-coordinator')) {
        return 'Customer-coordinator'
      }
      return null
    },

    async saveUpdateUser(user) {
      if (user.attributes.weeklyReservationMinuteLimit > 59) return
      user.attributes.rolesStatesChanged = false
      user.attributes.modifiedRoles = []
      if (this.insightsRoleState !== user.attributes.viewInsights)
        user.attributes.modifiedRoles.push({
          roleName: 'ViewInsights',
          isModified: user.attributes.viewInsights
        })

      if (this.teamLeadRoleState !== user.attributes.isTeamLead)
        user.attributes.modifiedRoles.push({
          roleName: 'TeamLead',
          isModified: user.attributes.isTeamLead
        })

      if (
        this.customerCoordinatorRoleState !==
        user.attributes.isCustomerCoordinator
      )
        user.attributes.modifiedRoles.push({
          roleName: 'CustomerCoordinator',
          isModified: user.attributes.isCustomerCoordinator
        })

      if (
        this.viewCustomAssetsRoleState !==
        user.attributes.viewCustomAssets
      ) {
        user.attributes.modifiedRoles.push({
          roleName: 'ViewCustomAssets',
          isModified: user.attributes.viewCustomAssets
        })
      }

      await this.updateUser(user)
      this.$emit('done')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/globals/mixins.scss';
.invalid-feedback {
  display: inline;
}
</style>
