<template>
  <b-modal
    :should-show="shouldShow"
    :on-enter-key-press="
      () => {
        shouldShow = false
      }
    "
    hide-footer
    size="md"
    :title="$t('user') + ' ' + $t('buidlingAccess')"
    @hidden="shouldShow = false"
  >
    <div v-if="user" class="d-block text-center">
      <b-overlay :show="loading" rounded="sm">
        <div class="row">
          <div class="col-6 text-left">
            <div class="form-group">
              <label>{{ $t('firstName') }}:</label>
              <input
                v-model="user.firstName"
                type="text"
                class="form-control"
                readonly
              />
            </div>
          </div>
          <div class="col-6 text-left">
            <div class="form-group">
              <label>{{ $t('lastName') }}:</label>
              <input
                v-model="user.lastName"
                type="text"
                class="form-control"
                readonly
              />
            </div>
          </div>
          <div
            v-for="group in groups"
            :key="group.path"
            class="col-12 text-left mb-2"
          >
            <b-input-group>
              <template #prepend>
                <b-input-group-text>
                  <img
                    v-if="group.logoUrl"
                    :src="group.logoUrl"
                    style="max-height: 30px; max-width: 40px"
                    :alt="group.name"
                  />
                  <font-awesome-icon
                    v-else
                    icon="building"
                    class="fa-1x"
                  />
                </b-input-group-text>
              </template>
              <b-form-input :value="group.name" disabled />

              <template #append>
                <div class="bg-info pt-2 pl-2">
                  <b-form-checkbox
                    v-model="group.checked"
                    @change="toggleAccess(group)"
                  />
                </div>
              </template>
            </b-input-group>
          </div>
        </div>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import _each from 'lodash/each'
import _keyBy from 'lodash/keyBy'

export default {
  name: 'UserBuildingAccessModal',
  components: {
    'b-modal': BootstrapModalHOC
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    user: {
      validator: user => typeof user === 'object' || user === null,
      required: true
    }
  },
  data() {
    return {
      shouldShow: this.show,
      errors: {},
      groups: []
    }
  },
  computed: {
    ...mapState('users', {
      loading: 'loading'
    }),
    ...mapState('common', {
      userInfo: '$userInfo'
    })
  },
  watch: {
    userInfo() {
      this.setGroups()
    },
    show() {
      this.shouldShow = this.show
    },
    shouldShow() {
      if (!this.shouldShow) {
        this.$emit('done')
      }
    },
    user(newValue) {
      if (newValue) {
        this.setCheckedGroups()
      } else {
        this.shouldShow = false
      }
    }
  },
  mounted() {
    this.setGroups()
  },
  methods: {
    ...mapActions('users', [
      'getUserGroups',
      'addUserToGroup',
      'removeUserFromGroup'
    ]),
    setCheckedGroups() {
      this.getUserGroups(this.user.id).then(groups => {
        const groupsWithAccess = _keyBy(groups, 'path')
        _each(this.groups, (group, key) => {
          if (groupsWithAccess[group.path]) {
            this.groups[key].checked = true
          } else {
            this.groups[key].checked = false
          }
        })
      })
    },
    toggleAccess(group) {
      if (group.checked) {
        this.removeUserFromGroup({
          userId: this.user.id,
          groupId: group.id
        })
      } else {
        this.addUserToGroup({
          userId: this.user.id,
          groupId: group.id
        })
      }
    },
    setGroups() {
      if (this.userInfo && this.userInfo.groups) {
        this.groups = []
        this.getUserGroups('me').then(myGroups => {
          const userInfoGroups = _keyBy(this.userInfo.groups, 'path')
          _each(myGroups, (group, key) => {
            if (userInfoGroups[group.path]) {
              this.groups.push({
                id: group.id,
                name: userInfoGroups[group.path].orgName,
                path: group.path,
                logoUrl: userInfoGroups[group.path]?.logoUrl,
                checked: false
              })
            }
          })
        })
      }
    }
  }
}
</script>

<style lang="scss"></style>
