<template>
  <b-modal
    id="upload-users-modal"
    :title="$t('addUsers')"
    :on-enter-key-press="uploadUsers"
    hide-footer8
    size="lg"
    hide-footer
    @hidden="hideUploadUserModal"
  >
    <b-alert
      dismissible
      fade
      :variant="alertVariant"
      :show="dismissCountDown"
      @dismiss-count-down="countDownChanged"
    >
      {{ alertText }}
    </b-alert>

    <div class="d-block text-center" style="margin-top:10px;">
      <label v-if="!orgAlias" style="color:red">{{
        $t('notAllowedToUploadUsers')
      }}</label>

      <div class="row">
        <div class="col-6 text-left">
          <div class="form-group">
            <label
              >Upload users CSV file
              <span style="color:red">*</span></label
            >
            <b-form-file
              v-model="file"
              :disabled="!orgAlias"
              :state="Boolean(file)"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
          </div>
        </div>
        <div class="col-2 text-left">
          <div class="form-group">
            <b-button
              style="margin-top:30px;"
              :disabled="!orgAlias"
              @click="validateFile"
              >{{ $t('validate') }}</b-button
            >
          </div>
        </div>
        <div class="col-4 text-left" style="margin-top: 37px;">
          <span
            class="action link-edit text-primary"
            style="font-size: 100%"
            @click="downloadExampleCsvFile"
          >
            <font-awesome-icon icon="file-export" />
            Example csv file
          </span>
        </div>
      </div>
      <template v-if="errors.length">
        <div
          v-for="(error, index) in errors"
          :key="index"
          class="row"
        >
          <div class="col-12 text-left">
            <label style="color: red;">{{ error }}</label>
          </div>
        </div>
      </template>

      <div class="row">
        <div class="col-6 text-left">
          <div class="form-group">
            <label>{{ $t('User Pool') }}</label>
            <b-form-input
              v-model="userPool"
              :disabled="!orgAlias"
              placeholder="Enter user pool"
            ></b-form-input>
          </div>
        </div>
        <div class="col-6 text-left">
          <div class="form-group">
            <label>{{ $t('Organization') }}</label>
            <b-form-input
              v-model="orgAlias"
              :disabled="!isDashboardAdmin"
              placeholder="Enter organization attribute"
            ></b-form-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-left">
          <div class="form-group">
            <label
              >{{ $t('Select Building') }}:
              <span style="color:red">*</span></label
            >
            <div>
              <multiselect
                v-model="selectedBuildings"
                :disabled="!orgAlias"
                :options="buildings"
                :multiple="true"
                label="displayName"
                track-by="displayName"
                :close-on-select="true"
                placeholder="Select Building"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="d-block text-right">
      <b-button
        variant="danger"
        class="m-1"
        @click="hideUploadUserModal"
      >
        {{ $t('cancel') }}
      </b-button>
      <b-button
        class="m-1"
        :disabled="disableStatus"
        primary
        @click="uploadUsers"
      >
        {{ $t('Upload') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import OkkuApi from '@/services/OkkuApi'
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'

import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      buildings: [],
      selectedBuildings: [],
      userPool: '',
      file: [],
      isFileValidated: false,
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: '',
      orgAlias: '',
      errors: [],
      busy: false
    }
  },
  computed: {
    ...mapState('common', {
      organization: '$organisation',
      userInfo: '$userInfo',
      isDashboardAdmin: '$isDashboardAdmin',
      isCustomerCoordinator: '$isCustomerCoordinator',
      isCustomerAdmin: '$isCustomerAdmin'
    }),
    disableStatus() {
      return (
        !this.isFileValidated ||
        !this.orgAlias ||
        this.busy ||
        !this.selectedBuildings.length
      )
    }
  },
  watch: {
    file() {
      this.isFileValidated = false
      this.error = []
    }
  },
  async mounted() {
    if (this.userInfo.organizationAlias) {
      this.orgAlias = this.userInfo.organizationAlias
    }
    if (
      this.isDashboardAdmin ||
      this.isCustomerCoordinator ||
      this.isCustomerAdmin
    ) {
      this.getOrgBuildings()
    }
  },
  methods: {
    async getOrgBuildings() {
      const allBuildings = await OkkuApi.getBuildings()

      const buildings = []
      allBuildings.forEach(({ id, displayName, name, path }) => {
        if (name === this.organization.split('/')[1]) {
          buildings.push({
            id,
            displayName,
            name,
            path
          })
        }
      })
      this.buildings = buildings
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    updateAlert(args) {
      this.dismissCountDown = 3
      this.alertVariant = args.variant
      this.alertText = args.text
    },
    showAlertMessage(variant, text) {
      this.updateAlert({
        variant,
        text
      })
    },
    async validateFile() {
      try {
        const formData = new FormData()

        formData.append('file', this.file)
        if (!this.file.name) {
          // No file is selected
          this.showAlertMessage('danger', this.$t('selectFile'))
          return
        }
        // Validate CSV
        const response = await OkkuApi.validateCsvFile(formData)

        if (!response.length) {
          this.showAlertMessage('success', this.$t('validFileInfo'))
          this.isFileValidated = true
        } else {
          this.errors = [...response]
          this.isFileValidated = false
        }
      } catch (error) {
        this.showAlertMessage('danger', this.$t('pleaseTryAgain'))
      }
    },
    async uploadUsers() {
      this.busy = true
      try {
        if (!this.isFileValidated) {
          this.showAlertMessage(
            'danger',
            this.$t('validateFileFirst')
          )
          return
        }

        if (!this.selectedBuildings.length) {
          this.showAlertMessage(
            'danger',
            this.$t('pleaseSelectBuilding')
          )
          return
        }

        const formData = new FormData()
        formData.append('file', this.file)

        formData.append('orgAlias', JSON.stringify(this.orgAlias))
        if (this.userPool) {
          formData.append('userPool', JSON.stringify(this.userPool))
        }
        formData.append(
          'buildings',
          JSON.stringify(this.selectedBuildings)
        )

        const { userId } = this.userInfo
        // Save file
        await OkkuApi.uploadUsers(formData, userId)

        // Show success message
        this.showAlertMessage('success', 'Changes saved successfully')
        setTimeout(() => {
          this.hideUploadUserModal()
          this.busy = false
        }, 2000)
      } catch (error) {
        this.showAlertMessage('danger', this.$t('pleaseTryAgain'))
        this.busy = false
      }
    },
    hideUploadUserModal() {
      this.$bvModal.hide('upload-users-modal')
      this.errors = []
      this.file = []
      this.selectedBuildings = []
      this.userPool = ''
    },
    async downloadExampleCsvFile() {
      const headers =
        'firstname; lastname; email; userpool; department'

      const url = window.URL.createObjectURL(new Blob([headers]), {
        type: 'text/plain'
      })

      // Create a link
      const link = document.createElement('a')
      link.href = url
      const filename = `Example-file.csv`

      // Append in DOM
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    }
  }
}
</script>
